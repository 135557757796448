<template>
  <div class="mt-10 z-10 inset-x-0 transform shadow-lg bg-white">
    <div class=" max-w-auto mx-auto">
      <form
        class="space-y-8 divide-y divide-gray-200 form_open_ticket_padding"
        @submit.prevent="
          selectTicketTemplate
            ? onSubmit($t('ticket.newTemplate'))
            : onSubmitReply($t('ticket.newTemplate'))
        "
        method="POST"
      >
        <div class="space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <div class="inline-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <h3 class="text-lg pl-2 leading-6 font-medium text-gray-900">
                  {{ $t("ticket.templateForm") }}
                </h3>
              </div>
            </div>

            <div class="mt-6 grid grid-cols-8 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="col-start-1 col-span-4">
                <fieldset class="mt-6" v-if="selectReplyTemplate">
                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        v-model="defaultTemplate"
                        id="comments"
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="comments" class="font-medium text-gray-700"
                        >Defaut</label
                      >
                      <span
                        id="comments-description"
                        class="text-gray-500 ml-0.5"
                        ><span class="sr-only">New comments </span>to make it
                        the default template.</span
                      >
                    </div>
                  </div>
                </fieldset>
                <label
                  v-if="selectTicketTemplate"
                  for="username"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ $t("ticket.subject") }}
                </label>
                <div class="mt-1 flex shadow-sm" v-if="selectTicketTemplate">
                  <input
                    id="subject"
                    v-model="form.subject"
                    name="subject"
                    type="text"
                    required=""
                    :placeholder="$t('ticket.emailSubject')"
                    class="flex-1 focus:encom_border_input  block w-full min-w-0 sm:text-sm border-gray-300"
                  />
                </div>
              </div>
              <div class="col-span-2">
                <label
                  for="country"
                  class=" mt-1 block text-sm font-medium text-gray-700"
                >
                  Template Name
                </label>
                <input
                  id="subject"
                  v-model="form.name"
                  name="subject"
                  type="text"
                  required=""
                  :placeholder="$t('ticket.emailSubject')"
                  class="flex-1 focus:encom_border_input  block w-full min-w-0 sm:text-sm border-gray-300"
                />
              </div>

              <div class="sm:col-span-6">
                <div class="mt-1 z-10">
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
                <p class="mt-2 text-sm text-gray-500">
                  {{ $t("ticket.typeAbove") }}.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <a
              @click.prevent="cancelForm"
              href="javascript:void(0)"
              class="rounded bg-white py-2 px-4 border border-gray-300  shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {{ $t("ticket.cancel") }}
            </a>
            <button
              type="submit"
              :class="
                `rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium  body__table--header text-white`
              "
            >
              {{ $t("ticket.send") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";

import ConfirmationDialog from "../ConfirmationDialog.vue";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";

export default {
  props: ["id", "selectTicketTemplate", "selectReplyTemplate"],
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        fillEmptyBlocks: false,
        tabSpaces: 0,
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
        ],
        toolbar: {
          items: [
            "heading",
            "bold",
            "italic",
            "|",
            "link",
            "|",
            "undo",
            "redo",
            "|",
            "indent",
            "outdent",
            "|",
            "bulletedList",
            "numberedList",
          ],
        },
        indentBlock: {
          offset: 1,
          unit: "em",
        },
        language: "en",
        licenseKey: "",
      },
      template: {},
      form: {
        subject: "",
        body: "",
        name: "",
      },
      showMessage: false,
      messageToShow: "",
      subMessageToShow: "",
      defaultTemplate: false,
    };
  },
  methods: {
    async onSubmit(msg) {
      let str = String(this.editorData);
      let noSpace = str.replaceAll("&nbsp;", "");
      let data = {
        subject: this.form.subject,
        body: noSpace,
        name: this.form.name,
      };
      axios
        .post(`${this.$cookie.getCookie("API")}/api/v1/ticketTemplates`, data)
        .then((res) => {
          if (res) {
            this.form.subject = "";
            this.form.body = "";
            this.form.name = "";
            // this.sendMessage(msg);
            this.$emit("activeBanner");
            this.$emit("success", true);
            this.$emit("successMsg", msg);
            this.$emit("ticketTemplate");
          } else {
            window.alert("Something went wrong.");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async onSubmitReply(msg) {
      let str = String(this.editorData);
      let noSpace = str.replaceAll("&nbsp;", "");
      let data = {
        body: noSpace,
        name: this.form.name,
        default: this.defaultTemplate,
      };
      axios
        .post(`${this.$cookie.getCookie("API")}/api/v1/replyTemplates`, data)
        .then((res) => {
          if (res) {
            this.form.body = "";
            this.form.name = "";
            this.defaultTemplate = false;
            // this.sendMessage(msg);
            this.$emit("activeBanner");
            this.$emit("success", true);
            this.$emit("successMsg", msg);
            this.$emit("replyTemplate");
          } else {
            window.alert("Something went wrong.");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
    cancelForm() {
      this.editorData = "";
      this.form.subject = "";
      this.form.body = "";
      this.form.name = "";
      this.$emit("cancelForm");
    },
  },
};
</script>

<style>
.new_template_height {
  height: 60vh;
  width: 100%;
}
.ck {
  height: 28vh;
}
@media only screen and (max-width: 700px) {
  .ck {
    height: 20vh;
  }
}
</style>
