<template>
  <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div
    class="relative bg-gray-50 pt-10 pb-20 px-4 sm:px-6 lg:pt-14 lg:pb-28 lg:px-8"
  >
    <div class="absolute inset-0">
      <div class="bg-white h-1/3 sm:h-2/3" />
    </div>
    <div class="relative max-w-7xl mx-auto">
      <div class="text-center">
        <!-- <h2
          class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl"
        >
          Your templates
        </h2> -->
        <!-- <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
          Click on template to edit it.
        </p> -->
      </div>
      <!-- tabs selector -->
      <div class="mt-0 mb-5">
        <div class="sm:hidden">
          <label for="tabs" class="sr-only">Select a tab</label>
          <select
            @change="selectTemplate($event)"
            id="tabs"
            name="tabs"
            class="block w-1/2 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          >
            <option
              v-for="tab in tabs"
              :key="tab.name"
              :selected="tab.current"
              :value="tab.value"
              >{{ tab.name }}</option
            >
          </select>
        </div>
        <div class="hidden sm:block">
          <nav
            class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
            aria-label="Tabs"
          >
            <a
              v-for="(tab, tabIdx) in tabs"
              :key="tab.name"
              @click="selectTemplate(tab.value)"
              :class="[
                tab.current
                  ? 'text-gray-900'
                  : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer',
              ]"
              :aria-current="tab.current ? 'page' : undefined"
            >
              <span>{{ tab.name }}</span>
              <span
                aria-hidden="true"
                :class="[
                  tab.current ? 'bg-indigo-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5',
                ]"
              />
            </a>
          </nav>
        </div>
      </div>
      <div class="mt-10">
        <TicketTemplateHeader
          @newTemplate="showNewTemplate = !showNewTemplate"
          v-model:search="search"
        />
        <!-- {{ search }} -->
      </div>
      <NewTemplate
        v-if="showNewTemplate"
        :selectTicketTemplate="selectTicketTemplate"
        :selectReplyTemplate="selectReplyTemplate"
        @cancelForm="showNewTemplate = false"
        @activeBanner="(activeBanner = true), (key = !key)"
        @successMsg="successMsg = $event"
        @success="isSuccess = $event"
        @ticketTemplate="getTicketTemplates()"
        @replyTemplate="getReplyTemplates()"
      />
      <div
        class="mt-10 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none"
        v-if="selectTicketTemplate"
      >
        <div
          v-for="template in filterTicketTemplate()"
          :key="template.id"
          class="flex flex-col rounded-lg shadow-lg overflow-hidden template_height"
        >
          <div class="p-0.5 absolute">
            <a
              href="javascript:void(0)"
              @click="
                deleteTemplate(
                  template.id,
                  $t('ticket.deleteTemplate'),
                  $t('ticket.deleteTemplateAskConfirmation'),
                  $t('delete'),
                  $t('dialog.goBack'),
                  $t('ticket.deleteTemplateConfirmation')
                )
              "
              class="tooltip"
            >
              <HoverTooltip text="Delete" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
          <div class="flex-1 bg-white p-6 flex flex-col justify-between -mt-5">
            <div class="flex-1">
              <p class="text-sm font-medium text-indigo-600">
                <a
                  href="javascript:void(0)"
                  @click="
                    this.$router.push(`/tickets-templates/${template.id}`)
                  "
                  class="hover:underline"
                >
                  {{ template.name }}
                </a>
              </p>
              <a class="block mt-2">
                <p class="text-xl font-semibold text-gray-900">
                  {{ template.subject }}
                </p>
                <span
                  class="mt-3 text-base text-gray-500"
                  v-html="template.body"
                ></span>
                <!-- <p class="mt-3 text-base text-gray-500">
                  {{ template.body }}
                </p> -->
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="selectReplyTemplate"
        class="mt-8 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none"
      >
        <div
          v-for="template in filterReplyTemplate()"
          :key="template.id"
          class="flex flex-col rounded-lg shadow-lg overflow-hidden template_height"
        >
          <div class="p-0.5 absolute">
            <a
              href="javascript:void(0)"
              @click="
                deleteReplyTemplate(
                  template.id,
                  $t('ticket.deleteTemplate'),
                  $t('ticket.deleteTemplateAskConfirmation'),
                  $t('delete'),
                  $t('dialog.goBack'),
                  $t('ticket.deleteTemplateConfirmation')
                )
              "
              class="tooltip"
            >
              <HoverTooltip text="Delete" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
          <div class="flex-1 bg-white p-6 flex flex-col justify-between -mt-5">
            <div class="flex-1">
              <p class="text-sm font-medium text-indigo-600">
                <a
                  href="javascript:void(0)"
                  @click="
                    this.$router.push(`/replies-templates/${template.id}`)
                  "
                  class="hover:underline"
                >
                  {{ template.name }}
                </a>
              </p>
              <a class="block mt-2">
                <span
                  class="mt-3 text-base text-gray-500"
                  v-html="template.body"
                ></span>
                <!-- <p class="mt-3 text-base text-gray-500">
                  {{ template.body }}
                </p> -->
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";

import ConfirmationDialog from "../../components/ConfirmationDialog.vue";
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";

import HoverTooltip from "../../components/HoverTooltip.vue";

import TicketTemplateHeader from "../../components/tickets/TicketTemplateHeader.vue";
import NewTemplate from "../../components/tickets/NewTemplate.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";

const tabs = [
  { name: "Ticket Templates", current: true, value: "ticket" },
  { name: "Reply Templates", current: false, value: "reply" },
];

export default {
  components: {
    ConfirmationDialog,
    AskConfirmationDialog,
    HoverTooltip,
    TicketTemplateHeader,
    NewTemplate,
    SuccessBanner,
  },
  data() {
    return {
      tabs,
      ticketTemplates: [],
      replyTemplates: [],
      selectTicketTemplate: true,
      selectReplyTemplate: false,
      showMessage: false,
      messageToShow: "",
      confirmationMessage: "",
      subMessageToShow: "",
      search: "",
      showNewTemplate: false,
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
    };
  },
  methods: {
    async getTicketTemplates() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/ticketTemplates`
        );
        this.ticketTemplates = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    filterTicketTemplate() {
      return this.ticketTemplates.filter((template) => {
        return (
          template.name.toLowerCase().includes(this.search.toLowerCase()) ||
          template.subject.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    async deleteTemplate(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const options = {
        method: "DELETE",
        url: `${this.$cookie.getCookie("API")}/api/v1/ticketTemplates/${id}`,
      };
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .request(options)
          .then((response) => {
            this.activeBanner = true;
            this.getTicketTemplates();
            this.key = !this.key;
            this.successMsg = successMsg;
            this.isSuccess = true;
          })
          .catch(function(error) {
            console.error(error);
            this.errorHandling(error);
          });
      }
    },
    async getReplyTemplates() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/replyTemplates`
        );
        this.replyTemplates = res.data;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
      }
    },
    filterReplyTemplate() {
      return this.replyTemplates.filter((template) => {
        return template.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    async deleteReplyTemplate(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const options = {
        method: "DELETE",
        url: `${this.$cookie.getCookie("API")}/api/v1/replyTemplates/${id}`,
      };
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .request(options)
          .then((response) => {
            this.activeBanner = true;
            this.getReplyTemplates();
            this.key = !this.key;
            this.successMsg = successMsg;
            this.isSuccess = true;
          })
          .catch(function(error) {
            console.error(error);
            this.errorHandling(error);
          });
      }
    },
    selectTemplate(event) {
      if (event === "ticket") {
        this.selectReplyTemplate = false;
        this.selectTicketTemplate = true;
        this.tabs[0].current = true;
        this.tabs[1].current = false;
      } else if (event === "reply") {
        this.selectReplyTemplate = true;
        this.selectTicketTemplate = false;
        this.tabs[0].current = false;
        this.tabs[1].current = true;
      }
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
  mounted() {
    this.getTicketTemplates();
    this.getReplyTemplates();
  },
};
</script>

<style>
.template_height {
  height: 230px;
  overflow-y: auto;
}
</style>
