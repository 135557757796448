<template>
  <div class="flex justify-between">
    <button
      @click="$emit('newTemplate')"
      class="px-2 py-2 body__table--header text-white rounded"
    >
      New template
    </button>
    <SearchFilter
      v-model:search="search"
      @input="$emit('update:search', this.search)"
      :inputPlaceholder="$t('header.searchTicketPlaceHolder')"
    />
  </div>
</template>

<script>
import SearchFilter from "../SearchFilter.vue";
export default {
  data() {
    return {
      search: "",
    };
  },
  components: {
    SearchFilter,
  },
};
</script>

<style></style>
